.layout-footer,
.layout-topbar {
  display: block;
}

.spanLabel {
  font-size: 11px;
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  width: 237px;
}

.spanData {
  font-size: 11px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
}

legend {
  text-align: left;
  font-size: 12px;
  font-weight: 700;
}

fieldset {
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 1px;
  border-style: groove;
  border-color: rgba(8, 91, 112, 0) !important;
  border-top-color: rgb(109, 117, 119) !important;
  border-right-color: rgb(109, 117, 119) !important;
  border-bottom-color: rgb(109, 117, 119) !important;
  border-left-color: rgb(109, 117, 119) !important;
  border-image: initial;
}

.buttonQuestion {
  font-size: 30px;
  text-align: right;
  padding-top: 6px;
}

#fieldSetTop {
  margin-top: -40px;
}
.resumo {
  background: #ccc;
}

.divLote {
  margin: 0 0px;
}

.frase {
  text-align: center;
}

.spanNomePesquisa {
    color:red;
}

body .p-component-overlay {
  background-color: rgba(235, 236, 239, 0.05);
}

.fieldSetInfo {
  padding-bottom: 15px;
  margin-bottom: 10px;
}

h4 {
  margin: 0px;
}

#popupLote {
  height: 330px;
  overflow-x: auto;
}

.p-column-title {
  display: block;
}

.p-datatable {
  margin: 5px;
}


/* retirar esses dois classes depois da publicação de poucas páginas */
.p-datatable-thead  tr  th  span {
  display: block !important;
}


body .p-editor-container .p-editor-toolbar {
   display: none;
}


body .p-editor-container .p-editor-content .ql-editor {
  background-color:transparent;
  font-size: 11px;

}