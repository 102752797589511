.login-content {
  /* background: url("../../assets/images/fundoLogin.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}

.login-body {
  z-index: 2;
}
.login-body2 {
  background-image: url("../../assets/images/fundoLogin.png");
  height: 800px;
}

.layout-footer {
  display: none !important;
}

.login-body {
  padding: 0;
  margin: 0;
  min-height: 100%;
}
.login-body .login-panel {
  display: none;
}
.login-body .login-content {
  position: fixed;
  padding: 80px 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;
}
.login-body .login-content img {
  display: inline-block;
  margin-bottom: 40px;
  width: 300px;
}
.login-body .login-content h1 {
  font-size: 28px;
  font-weight: 500;
  margin: 14px 0;
  color: #212121;
}
.login-body .login-content h1 span {
  color: #727272;
}
.login-body .login-content p {
  font-size: 17px;
  color: #727272;
  margin: 0 0 32px 0;
}
.login-body .login-content .login-input-wrapper {
  position: relative;
  width: 300px;
  margin-bottom: 24px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.login-body .login-content .login-input-wrapper input {
  width: 100%;
  padding-right: 16px;
}
.login-body .login-content .login-input-wrapper i {
  position: absolute;
  color: #727272;
  font-size: 16px;
  top: 50%;
  right: 0.429em;
  margin-top: -8px;
}
.login-body .login-content button {
  min-width: 150px;
}

.p-password-panel {
  display: none !important;
}

.login-input-wrapper {
  margin-bottom: 10px !important;
}

@media screen and (max-width: 567px){
  .login-body .login-content p {
    color: red
  }

}

@media screen and (min-width: 768px) {


  
#rootLogin{
  margin: -65px -15px 0 -270px;
  height: 140%;
  background-image: url("../../assets/images/fundoLogin.png") !important;
  background-size: cover;
}


  .layout-wrapper.layout-overlay .layout-topbar {
    display: none;
  }

  .login-body {
    /* background: url("../images/pages/bg-login.jpg"); */
    background-size: cover;
  }

  .login-body .login-panel {
    padding: 80px;
    display: block;
    position: fixed;
    background-color: #ffffff;
    height: 200%;
    width: 100%;
    left: -50%;
    top: -50%;
    opacity: 0.95;
    filter: alpha(opacity=95);
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .login-body .login-content {
    text-align: left;
    position: fixed;
    padding: 80px 0 0 80px;
  }
  .login-body .login-content .login-input-wrapper {
    width: 300px;
    margin-left: 0;
    margin-right: 0;
    text-align: left;
  }

  .login-body .login-content p {
    width: 300px;
}

}


@media screen and (max-width: 575px) {
  .login-body {
    background-size: cover;
  }

  #rootLogin {
    margin: -65px -41px 0px -17px;
    height: 140%;
    background-image: none !important;
    padding-left: 15px;
    background-color: white;
  }
  

  .layout-main {
    /* background-image: url(/static/media/fundo_rede.06c37bcb.png); */
    background-size: inherit;
    background-repeat: no-repeat;
    background-position-y: 55%;
    background-position-x: inherit;
  }

  .login-body2 {
    background-image: none;
    height: 700px;
  }

  .p-inputtext .p-component .login-input-wrapper {
    padding-right: 10px;
  }

  .login-input-wrapper {
    padding-right: 10px;
  }

  .login-body .login-content img {
    display: inline-block;
    margin-bottom: 0;
    width: 200px;
  }

  .login-body .login-content p {
    font-size: 15px;
    color: #000000;
    margin: 20px 30px 40px 15px;
  }

  .login-body .login-content {
    padding: 30px 20px 30px 0;
  }


}

/* Add your customizations of layout here */

