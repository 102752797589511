/* Place your extensions here to make migration to a new release easier. */

#app {
  height: "100%";
  width: "100%";
}

.layout-topbar {
  background: #00527d;
}

.layout-topbar {
  background: linear-gradient(to right, #015d86 0%, #07bdf4 100%);
  box-shadow: rgba(6, 13, 23, 1) 0 4px 8px;
}

.p-dropdown {
  width: 100%;
}

body .p-inputtext {
  width: 100%;
}

label {
  display: table-cell;
  font-size: 13px;
  font-weight: 100;
}

body .p-datepicker table td {
  padding: 0;
}

.div-botoes {
  text-align: right;
  padding-top: 23px;
}

.div-botoes-left {
  text-align: left;
  padding-top: 24px;
}
body .p-button {
  background-color: #00527d;
  border: 1px solid #00527d;
}

.card h1:first-child {
  font-weight: bold;
  font-size: 26px;
  color: #00527d;
  margin: 0 0 0 -5px;
  font-weight: bold;
  font-size: 27px;
  font-stretch: ultra-expanded;
}

h2 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
}

.p-fileupload-choose {
  position: relative;
  overflow: hidden;
  background-color: #4c4c4c;
  border: 1px solid #4c4c4c;
}

.p-fileupload-s:hover {
  position: relative;
  overflow: hidden;
  background-color: #ccc;
  border: 1px solid #ccc;
}

.green {
  color: #035e1e;
  padding: 0;
  margin: 0;
  font-size: 24px !important;
}

.red {
  color: #850000;
  padding: 0;
  margin: 0;
  font-size: 24px !important;
}

.alignRight {
  float: right;
  text-align: right;
}

.secundary {
  background-color: #4c4c4c !important;
  border: 1px solid #4c4c4c !important;
}

.disabled {
  background-color: #4c4c4c !important;
  border: 1px solid #4c4c4c !important;
}

.layout-wrapper.layout-overlay .layout-topbar {
  left: 0;
  -moz-transition: left 0.3s;
  -o-transition: left 0.3s;
  -webkit-transition: left 0.3s;
  transition: left 0.3s;
  display: inline-block;
}

body .p-fileupload .p-fileupload-content {
  background-color: none;
  padding: none;
  border: none;
  color: none;
}

body .p-fileupload .p-fileupload-buttonbar {
  background-color: none;
  padding: 0.571em 1em;
  border: none;
}

.top6 {
  margin-top: 6px;
}

body .p-fileupload .p-fileupload-buttonbar {
  background-color: #fff;
}

body {
  background-size: 100%;
  /* background-image: url(../src/assets/images/fundotempoCidades.jpg); */
  overflow-y: hidden;
}

.layout-sidebar-dark {
  background-color: rgb(0, 93, 133);
  background-image: url("../src/assets/images/fundoazul_rede.jpg") !important;
  background-position-x: center;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 110%;
  box-shadow: rgba(5, 8, 6, 1) 0 60px 15px;
}

.layout-sidebar {
  overflow: hidden;
}

.tituloBarraTop {
  color: white;
  text-align: left;
  font-family: "Myriad Pro", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
  vertical-align: super;
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
}

.layout-wrapper.layout-overlay .layout-topbar {
  padding-top: 10px;
}

body .p-editor-container .p-editor-toolbar {
  border: none;
  background-color: #fff;
}

body .p-editor-container .p-editor-content {
  border: none;
}

.pi-question-circle {
  font-size: 28px !important;
}

body .p-disabled,
body .p-component:disabled {
  opacity: 0.6;
  filter: alpha(opacity=50);
}

.rodape {
  color: #fff;
  text-align: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
  font-size: 11px;
  font-family: monospace;
}

.lateral {
  position: relative;
}

.card-tela-inteira {
  // height: 650px;
}

.div-botoes .p-button {
  margin-left: 20px;
}

body .p-component {
  font-family: "Myriad Pro", Myriad, Arial, sans-serif, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 13px;
  text-decoration: none;
}

.layout-sidebar-dark .layout-menu li a:hover {
  color: rgb(230, 180, 49);
}

.layout-sidebar-dark .layout-menu > li ul {
  background-color: #0d719cd9;
}

.layout-sidebar-dark .layout-menu li.active-menuitem > a {
  background-color: #0d719cd9;

  color: rgb(230, 180, 49);
  border: none !important;
}

.layout-menu > li ul li a {
  font-size: 12px;
  padding: 1em 1em 0em 1em;
}

.layout-menu > li > a span {
  font-size: 12px;
}

.layout-menu li a i {
  font-size: 16px;
}

.layout-menu > li > a {
  padding: 0.9em;
}

.active-menuitem {
  border-top: 1px solid rgba(52, 56, 65, 0.6);
  border-bottom: 1px solid rgba(52, 56, 65, 0.6);
}

.username_top {
  vertical-align: super;
}

body .pi-trash {
  font-size: 1.5em;
  color: #00527d;
}

fieldset {
  border-style: solid !important;
  border-radius: 3px;
  border-top-color: #c3c3c3 !important;
  border-right-color: #c3c3c3 !important;
  border-bottom-color: #c3c3c3 !important;
  border-left-color: #c3c3c3 !important;
}

.layout-profile img {
  margin: 1px;
}

.layout-profile lateral {
  padding-top: 7px;
}

.card-w-master {
  box-shadow: #2b3033 2px 2px 7px 2px;
  overflow: auto;
}

.p-column-filter {
  width: 100%;
  font-size: 12px !important;
  padding: 5px 9px 5px 5px !important;
  font-variant: all-petite-caps;
  font-weight: bold;
  display: table;
}

h2 {
  margin: 7px 1px 10px 0px;
}


body .p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #eaeef7;
}


body .p-datatable .p-datatable-thead > tr > th {
  color: #005c85;
  background-color: #eaeef7;
}

body .p-datatable .p-datatable-header, body .p-datatable .p-datatable-footer {
  background-color: #eaeef7;
  color: #005c85;
}

.logoMMS{
    margin: 30px 0 0 16px;
    background-color: #fff;
    width: 217px;
    text-align: center;
    padding: 20;

}


.linkDownload, .linkDownload a{
  margin: 5px 5px 0px 0px;
  text-decoration: none !important;
  color: #121213;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}